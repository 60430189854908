
.play-button {
    z-index: 40;
    width: 16px; /* or whatever size you want */
    height: 16px;
    filter: invert(1); /* this will make the image white */
    cursor: pointer;
    margin-right: 20px;
}

.container {
  width: 86%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.video-container {
    width: 100%;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .flex-column {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    display: flex;
    z-index: 30;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .video-controls {
    position: absolute;
    height: 100%;
    z-index: 40;
    top: 0;
    left: 0;
    width: 100%;
    /* pointer-events: none; */
  }

  video {
    position: relative;
    border-radius: 10px;
  }


.circle {
  position: absolute;
  top: -3px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: opacity 0.5s ease-in-out;
}

.circle.show {
  opacity: 1;
}

.fullscreen-button {
    margin-right: 0;
}

  .time-display {
    width: 90%;
    display: flex;
    flex-direction: horizontal;
    color: white;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    bottom: 14%;
    align-items: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

  }

  .time_progressbarContainer {
    background-color: white;
    opacity: 0.9;
    border-radius: 15px;
    width: 90%;
    height: 7px;
    z-index: 30;
    position: absolute;
    bottom: 8%;
    cursor: pointer;
    /* padding: 10px; add padding to increase hover area */

  }
  
  .time_progressBar {
    border-radius: 15px;
    z-index: 20;
    background-color: #e19ac7;
    height: 100%;
  }
