.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-bar {
  transition: width 1s ease-out;
}

.breathing-animation {
  animation-name: breathing;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 5;
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.scrollbar-custom {
  overflow-x: hidden;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 3px;
  border-radius: 1.5px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 1.5px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: rgba(180, 180, 180, 0.6);
  border-radius: 1.5px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: rgba(150, 150, 150, 0.8);
}
