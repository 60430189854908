/* .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 800px;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .slider-container {
    display: flex;
    width: 384px;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
  }
  
  .slider-bar-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8px;
    background: linear-gradient(90deg, #00FFC7, #00BFFF);
    border-radius: 5px;
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  
  .slider-bar-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8px;
    background: linear-gradient(90deg, #FF0080, #FF8C00);
    border-radius: 5px;
  }
  
  .slider {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 8px;
    background: none;
    outline: none;
    cursor: pointer;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #FFFF00;
  } */

.sliderContainer {
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.slider1Wrapper {
    rotate: 180deg;
}

/* .slider2Wrapper {
    width: 50%;
} */

.slider {
    background: #f2efee;
    display: flex;
    height: 40px;
    border: 2px solid #000;
    border-left: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.rangeSlider {
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.rangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 21px;
    border-radius: 10%;
    background-image: url("../../images/clipTrimHandle.jpg");
    background-size: cover;
    border-radius: 30%;
    border: 2px solid #000;
  }

  .rangeSlider::-moz-slider-thumb {
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 21px;
    border-radius: 50%;
    background-image: url("../../images/clipTrimHandle.jpg");
    background-size: cover; 
  }